exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/Contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-develop-jsx": () => import("./../../../src/pages/Develop.jsx" /* webpackChunkName: "component---src-pages-develop-jsx" */),
  "component---src-pages-illastration-jsx": () => import("./../../../src/pages/Illastration.jsx" /* webpackChunkName: "component---src-pages-illastration-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-photography-jsx": () => import("./../../../src/pages/Photography.jsx" /* webpackChunkName: "component---src-pages-photography-jsx" */),
  "component---src-pages-suriel-home-jsx": () => import("./../../../src/pages/SurielHome.jsx" /* webpackChunkName: "component---src-pages-suriel-home-jsx" */),
  "component---src-pages-test-mdx": () => import("./../../../src/pages/test.mdx" /* webpackChunkName: "component---src-pages-test-mdx" */),
  "component---src-templates-foto-directory-jsx": () => import("./../../../src/templates/FotoDirectory.jsx" /* webpackChunkName: "component---src-templates-foto-directory-jsx" */),
  "component---src-templates-foto-view-jsx": () => import("./../../../src/templates/FotoView.jsx" /* webpackChunkName: "component---src-templates-foto-view-jsx" */),
  "component---src-templates-post-jsx-content-file-path-blog-posts-a-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/blog/posts/a.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-blog-posts-a-mdx" */),
  "component---src-templates-post-jsx-content-file-path-blog-posts-b-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/blog/posts/b.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-blog-posts-b-mdx" */),
  "component---src-templates-post-jsx-content-file-path-blog-posts-xx-xx-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/opt/buildhome/repo/blog/posts/xx/xx.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-blog-posts-xx-xx-mdx" */),
  "component---src-templates-posts-jsx": () => import("./../../../src/templates/posts.jsx" /* webpackChunkName: "component---src-templates-posts-jsx" */)
}

